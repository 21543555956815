<template>
  <v-card class="test-plans-section-header white pt-4 px-6 pb-4 mt-2 mb-2" rounded="lg" elevation="0" width="100%">
    <div class="d-flex flex-row align-center justify-space-between">
      <h2>{{ $t('plans.list.testRuns') }}</h2>
    </div>
    <div class="mt-4 d-flex">
      <v-chip
        class="font-inter font-weight-bold px-4"
        width="200px"
        label
        :class="{ 'blue--text': tab === 'all' }"
        :color="tab === 'all' ? 'blue-light' : 'gray-light'"
        @click="$emit('tab', 'all')"
      >
        {{ $t('all') }} <span class="ml-2">{{ ongoingCount }}</span>
      </v-chip>
      <v-chip
        class="font-inter font-weight-bold px-4 ml-2"
        label
        width="200px"
        :class="{ 'blue--text': tab === 'selected' }"
        :color="tab === 'selected' ? 'blue-light' : 'gray-light'"
        @click="$emit('tab', 'selected')"
      >
        {{ $t('selected') }} <span class="ml-2">{{ archivedCount }}</span>
      </v-chip>
    </div>
    <slot name="additional-actions" />
  </v-card>
</template>

<script>
export default {
  name: 'DetailSectionHeader',

  props: ['tab', 'data'],
  computed: {
    ongoingCount() {
      return this.data.filter((x) => !x.archived).length;
    },
    archivedCount() {
      return this.data.filter((x) => x.archived).length;
    },
  },
  methods: {
    updateFilter(filter) {
      this.$emit('update-filter', filter);
    },
  },
};
</script>

<style lang="scss" scoped>
.test-plans-section-header {
  h2 {
    font-weight: 700;
  }

  .btn-create {
    height: 40px;
  }

  .subtitle {
    & > button {
      padding: 0 16px;
      height: 28px;
      border-radius: 6px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;

      display: inline-flex;
      align-items: center;

      span {
        margin-left: 6px;
      }
    }

    & > button:nth-child(1) {
      background: #e6ecff;
      color: #0c2ff3;
    }

    & > button:nth-child(2) {
      color: #667085;
    }

    & > button + button {
      margin-left: 6px;
    }
  }
}

.horizontal-margin {
  margin: 0px 10px;
}

.tray-btn-margin {
  min-width: 40px !important;
  width: 40px !important;
  padding: 10px 0px !important;
}

.tray-btn-outline {
  border-radius: 8px;
  box-shadow: none;
}
</style>
