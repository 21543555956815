<template>
  <div class="test-plans-detail font-inter pl-2 pr-2 pb-2" fluid>
    <v-card class="test-plans-section-header white pt-4 px-6 pb-4 mt-2" rounded="lg" elevation="0" width="100%">
      <div class="d-flex flex-column">
        <div class="d-flex flex-row align-center justify-space-between">
          <button class="btn-back d-flex" @click="handleBackClick">
            <v-icon color="blue">mdi-chevron-left</v-icon>
            <span class="d-flex-inline justify-center align-center ma-0 blue--text font-weight-bold">
              {{ $t('plans.create.backToPlans') }}
            </span>
          </button>
          <div class="d-flex flex-row justify-end align-center">
            <h4 class="font-weight-light mr-2">Status:</h4>
            <div>
              <v-menu bottom offset-y :nudge-top="4" right v-model="menuOpen">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    dark
                    large
                    color="gray-100"
                    class="text-capitalize font-weight-light black--text px-3 py-2"
                    depressed
                    height="40"
                  >
                    {{ testPlan.status }}
                    <v-icon size="20">{{ menuOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </template>
                <v-list dense class="text-left">
                  <v-list-item  @click="testPlan.status = 'new'">
                    <v-list-item-title>{{ $t('plans.edit.new') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="testPlan.status = 'in progress'">
                    <v-list-item-title>{{ $t('plans.edit.inProgress') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="testPlan.status = 'passed'">
                    <v-list-item-title>{{ $t('plans.edit.passed') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="testPlan.status = 'rerun'">
                    <v-list-item-title>{{ $t('plans.edit.rerun') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="testPlan.status = 'in failed'">
                    <v-list-item-title>{{ $t('plans.edit.failed') }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column justify-start">
          <v-hover v-if="!isEditPlan" v-slot="{ hover }">
            <div class="d-flex flex-row">
              <h2 class="edit-plan">{{ testPlan.name }}</h2>
              <button class="btn-hide" :class="{'btn-show': hover}" @click="handleEditPlanDetail"><v-icon>mdi-pencil-outline</v-icon></button>
            </div>
          </v-hover>
          <v-text-field v-else v-model="testPlan.name" class="plan-name edit-plan font-weight-bold" solo flat hide-details="true" background-color="#F9FAFB"></v-text-field>
          <p v-if="!isEditPlan" class="edit-plan" style="color: #667085; font-size: 14px; line-height: 14px">
            {{ testPlan.description }}
          </p>
          <v-textarea v-else v-model="testPlan.description" class="edit-plan pt-0 mt-1 plan-description" dense solo flat auto-grow hide-details="true" background-color="#F9FAFB" height="44"></v-textarea>
        </div>
        <div class="d-flex flex-row justify-space-between">
          <div class="d-flex flex-column">
            <h4>{{ $t('plans.edit.milestone') }}</h4>
            <v-select
              filled
              dense
              color="#F9F9FB"
              v-model="testPlan.test_milestoneUid"
              :items="items"
              full-width
              item-key="uid"
              item-value="uid"
              item-text="name"
              class="rounded-lg"
              append-icon="mdi-chevron-down"
              hide-details="true"
            ></v-select>
          </div>
          <div class="d-flex flex-column">
            <h4>{{ $t('plans.edit.priority') }}</h4>
            <v-select
              filled
              dense
              color="#F9F9FB"
              v-model="testPlan.priority"
              :items="priorities"
              full-width
              class="rounded-lg"
              append-icon="mdi-chevron-down"
              hide-details="true"
            ></v-select>
          </div>
          <div class="d-flex flex-column">
            <h4>{{ $t('plans.edit.tags') }}</h4>
            <v-select
              filled
              dense
              color="#F9F9FB"
              v-model="testPlan.customFields.tags"
              :items="tags"
              full-width
              class="rounded-lg"
              append-icon="mdi-chevron-down"
              hide-details="true"
            ></v-select>
          </div>
        </div>
      </div>
    </v-card>
    <detail-section-header :tab="tableFilter" :data="runItems" @tab="handleTab" />
    <div class="test-plans-detail-wrapper">
      <placeholder v-if="runItems.length === 0" />
      <v-card class="py-6 px-6" rounded="lg" elevation="0" width="100%" v-else>
        <div class="d-flex">
          <search-field class="search-input mr-2" v-model="searchFilter" :placeholder="$t('searchByName')" />
          <!-- <plans-list-filter @filters="applyFilters" /> -->
          <v-menu left :close-on-content-click="false" :nudge-bottom="4" offset-y min-width="191">
            <template v-slot:activator="{ on }">
              <v-btn class="ml-auto pa-0 rounded-lg" height="40" min-width="40" elevation="0" v-on="on">
                <v-icon size="16px"> mdi-cog-outline </v-icon>
              </v-btn>
            </template>
            <v-list width="191" class="d-flex flex-column justify-space-between rounded-xl">
              <v-list-item class="font-inter custom-text-14 custom-menu-item my-2">{{ $t('columns') }}</v-list-item>
              <v-list-item v-for="(header, index) in filteredMenuHeaders" class="custom-menu-item" :key="index">
                <v-checkbox
                  v-model="header.isSelected"
                  :value="header.isSelected"
                  :true-value="true"
                  :false-value="false"
                  off-icon="mdi-checkbox-blank"
                  class="ma-0 pa-0"
                  color="blue"
                  :size="16"
                  hide-details
                >
                  <template v-slot:label>
                    <div class="custom-color-0c111d font-inter custom-text-14">{{ header.text }}</div>
                  </template>
                </v-checkbox>
              </v-list-item>
              <v-list-item class="custom-menu-item my-2" @click="handleColumnReset()">
                <div
                  class="custom-text-12 font-weight-semibold font-inter custom-color-d0d5dd"
                  :class="{ 'blue--text font-weight-semibold ': isColumnFilter }"
                >
                  {{ $t('Restore default') }}
                </div>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <test-run-list
          :data="runItems"
          :tab="tableFilter"
          :selectedRuns="testPlan.runs"
          :headers="filteredHeaders"
          @selectTestRun="selectTestRun"
        />
      </v-card>

      <div class="d-flex flex-row justify-end py-4">
        <v-btn class="px-6 text-capitalize mr-3 action-btn" @click="handleCancel">{{ $t('cancel') }}</v-btn>
        <v-btn class="px-6 text-capitalize primary action-btn" @click="handleUpdate">{{ $t('save') }}</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters, mapState } from 'vuex';

import DetailSectionHeader from '@/views/Tests/Plans/Components/DetailSectionHeader';
import Placeholder from '@/views/Tests/Plans/Components/Placeholder';
import SearchField from '@/components/Form/SearchField';
import TestRunList from '@/views/Tests/Plans/Components/TestRunList';

import { testPlansProgressColor } from '@/constants/data.js';
import {
  runStateMap,
  TestPlansTestRunsTableHeaderAll,
  TestPlansTestRunsTableHeaderSelected,
} from '@/constants/grid.js';
import makePlanService from '@/services/api/plan';
const { mapActions } = createNamespacedHelpers('plan');
const { mapActions: mapMilestoneActions } = createNamespacedHelpers('milestone');
const { mapActions: MapRunActions } = createNamespacedHelpers('run');
let planService;
export default {
  components: {
    Placeholder,
    SearchField,
    DetailSectionHeader,
    TestRunList
  },
  data() {
    return {
      menuOpen: false,
      isEditPlan: false,
      testPlanData: {},
      priorities: ["High", "Medium", "Low"],
      isColumnFilter: false,
      headers: TestPlansTestRunsTableHeaderAll,
      searchFilter: "",
      tableFilter: 'all',
      planDescription: "",
      planName: "Functionality Test Plan",
      value: {
        id: 1,
        name: 'Smoke Test Run',
        priority: 'High',
        status: 'New',
        configurations: [{ title: 'Browsers' }],
        creationdate: '06/17/2023',
        tag: ['#projectateam'],
        progress: [20, 40, 60, 80],
      },
      tags: ["#smoke","#regression","#feature","#security","#integration"],
    };
  },
  computed: {
    ...mapState('plan', ['selectedRuns']),
    ...mapState('milestone', ['items']),
    ...mapState('run', {
      runItems: state => state.items
    }),
    ...mapState('plan', ['testPlan']),
    ...mapGetters({
      currentOrg: 'user/currentAccount',
    }),
    filteredHeaders() {
      const headers = this.tableFilter === 'all' ? TestPlansTestRunsTableHeaderAll : TestPlansTestRunsTableHeaderSelected;
      const filtered = headers.filter((x) => x.isSelected);
      if (filtered.length < this.headers.length)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = true
      else
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = false
      return filtered;
    },
    filteredMenuHeaders() {
      const filtered = this.headers.filter((header) => header.text != 'Actions');
      return filtered;
    },
  },
  created() {
    planService = makePlanService(this.$api);
  },
  mounted() {
    const { handle, planId, key } = this.$route.params;

    this.findTestPlan({ handle, planId, key })
    this.get({ handle, projectKey: key });
    this.getAllTestRuns({ handle: handle, projectKey: this.$route.params.key, perPage: 99999, currentPage: 0 });
  },
  methods: {
    ...mapActions(['findTestPlan']),
    ...MapRunActions(['getAllTestRuns']),
    ...mapMilestoneActions(['get']),
    handleCancel() {},
    async handleUpdate() {
      const payload = {
        name: this.testPlan.name,
        description: this.testPlan.description,
        externalId: "1",
        source: "testfiesta",
        status: this.testPlan.status.toLowerCase(),
        milestoneId: this.testPlan.test_milestoneUid,
        priority: this.testPlan.priority,
        runs: this.testPlan.runs,
        customFields: {
          status: this.testPlan.status.toLowerCase(),
          tags: this.testPlan.tags,
          configurations: 'Browsers',
          archived: false
        }
      }
      try{
        const response = await planService.updateTestPlan(
          this.$route.params.handle,
          this.$route.params.key,
          this.testPlan.uid,
          payload,
        );
        if(response.status === 200) {
          this.$router.push({
            name: 'TestPlans',
            params: {
              handle: this.$route.params.handle,
              key: this.$route.params.key,
            }
          });
        }
      } catch(err){
        console.log(err);
      }
    },
    selectTestRun(selectedRuns) {
      this.testPlan.runs = selectedRuns
    },
    handleTab(value) {
      this.tableFilter = value;
    },
    handleEditPlanDetail() {
      this.isEditPlan = true;
    },
    handleBackClick() {
      this.$router.replace({ name: 'TestPlans' });
    },
    handleColumnReset() {
      this.headers = this.headers.map((header) => {
        header.isSelected = true;
        return header;
      })
    },
    progress(item) {
      const result = item.progress.map((x) => {
        const color =
          x < 25
            ? testPlansProgressColor[0]
            : x < 50
            ? testPlansProgressColor[1]
            : x < 75
            ? testPlansProgressColor[4]
            : testPlansProgressColor[3];
        return {
          value: x,
          color,
        };
      });
      return result;
    },
  },
};
</script>

<style lang="scss">
.test-plans-detail {
  height: 100%;
  max-height: calc(100vh - 90px);
  background: #f2f4f7;

  display: flex;
  flex-direction: column;
}

.test-plans-detail-wrapper {
  height: 100%;
  max-height: calc(100% - 116px - 8px);
  background: #ffffff;
  border-radius: 8px;
}

.progress-container {
  position: relative;
  width: 158px;
  height: 8px;
  background: #eaecf0;
  overflow: hidden;
}

.btn-hide{
  display: none;
}

.btn-show{
  display: block !important;
}

.v-input.plan-name{
  .v-input__control{
    .v-input__slot{
      padding-left: 4px !important;
      input{
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
}

.edit-plan{
  max-width: 476px;
}

.plan-description{
  .v-input__control{
    .v-input__slot{
      padding-left: 4px !important;
      textarea{
        line-height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #667085;
        height: 44px !important;
      }
    }
  }
}

.action-btn{
  width: 140px;
}
</style>
